@import 'mixins/brand-colors';

.brand--southwestern {
    @include brand-colors(
        $color1: #FFCD03,
        $hover1: lighten(#FFCD03, 20%),
        $color2: #000000,
        $hover2: lighten(#111111, 30%),
        $dark: #000000,
        $light: #FFCD03
    );
}
