@import 'mixins/brand-colors';

.brand--texas-christian {
    @include brand-colors(
        $color1: #4D1979,
        $hover1: lighten(#4D1979, 20%),
        $color2: #ffffff,
        $hover2: darken(#ffffff, 10%),
        $dark: #4D1979,
        $light: #ffffff
    );
}
