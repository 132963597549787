@import 'mixins/brand-colors';

.brand--stanford {
    @include brand-colors(
        $color1: #b1040e,
        $hover1: darken(#b1040e, 10%),
        $color2: #d2c295,
        $hover2: darken(#d2c295, 10%),
        $dark: #b1040e,
        $light: #ffffff
    );
}
