$mid-lum: lightness(#7f7f7f);
@function color-is-dark($color) {
    $lum: lightness($color);
    @return ($lum < $mid-lum);
}
@function generic-contrasting-color($color) {
    @if color-is-dark($color) {
        @return white;
    } @else {
        @return black;
    }
}
@mixin theme-bg-color($color) {
    background-color: $color;
    color: generic-contrasting-color($color);
}
@mixin theme-bg-color-hover($color, $hover) {
    background-color: $color;
    color: generic-contrasting-color($color);

    &:focus,
    &:hover {
        background-color: $hover;
        color: generic-contrasting-color($hover);
    }

    &:disabled {
        background-color: #e9e9e9; /*sogeti EDTSD*/
        color: #000;
        pointer-events: none; /*sogeti EDTSD*/
    }
}
@mixin brand-colors($color1, $hover1, $color2, $hover2, $dark, $light) {
    $brand-colors: $color1, $color2;

    .theme-1 {
        @include theme-bg-color($color1);
    }

    .theme-2 {
        @include theme-bg-color($color2);
    }

    .theme-button-1 {
        @include theme-bg-color-hover($color1, $hover1);
    }

    .theme-button-2 {
        @include theme-bg-color-hover($color2, $hover2);
    }

    .theme-dark {
        color: $dark;
    }

    .theme-dark-hover {
        &:focus,
        &:hover {
            color: $dark;
        }
    }

    .theme-light {
        color: $light;
    }

    .theme-light-hover {
        &:focus,
        &:hover {
            color: $light;
        }
    }
}