@import 'mixins/brand-colors';

.brand--follett {
    @include brand-colors(
        $color1: #01338e,
        $hover1: lighten(#01338e, 10%),
        $color2: #e67001,
        $hover2: darken(#e67001, 10%),
        $dark: #01338e,
        $light: #ffffff
    );
}
